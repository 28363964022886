<!-- @format -->

<template>
  <div class="my-2">
    <div
      v-if="isForwardModalVisible"
      class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
    >
      <RejectReasonModal
        @submit-modal="submitRejection"
        @close-modal="closeRejectModal"
        :doctorsProfiles="doctorsProfiles"
        :isApproved="isApproved"
        :key="doctorsProfiles"
      />
    </div>
    <div
      v-if="isModalVisible"
      class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
    >
      <div class="bg-white px-4 pb-2 rounded-md shadow-lg">
        <div class="flex justify-end my-2">
          <button
            class="material-icons bg-red-500 rounded-full text-white w-6"
            @click="isModalVisible = false"
          >
            close_outliend
          </button>
        </div>
        <VideoPlayer
          :src="selectedFile?.name?.includes('Evidence Video') ? videoSrc : ''"
          @close="isModalVisible = false"
        />
      </div>
    </div>
    <div class="flex mb-4 gap-6 items-center">
      <div class="p-2 rounded-lg bg-gray-500 bg-opacity-10">
        <img
          src="@/assets/icons/Certificate.svg"
          class="w-10"
          alt="Certificate"
        />
      </div>
      <div v-if="isLoading">
        <div class="h-6 bg-gray-300 rounded-md mb-2.5"></div>
        <div class="h-4 bg-gray-300 rounded-md w-32"></div>
      </div>
      <div v-else>
        <h1 class="text-2xl font-medium">
          {{ medicalLetter?.data?.medical_letter?.name }}
        </h1>
        <p class="text-textGray">
          {{ medicalLetter?.data?.full_name }}
        </p>
      </div>
    </div>
    <!-- {{ medicalLetterQuestions?.Questionnaires[0]?.Categories }} -->
    <div v-if="isLoading" class="bg-white px-6 py-4 my-4">
      <div class="h-6 bg-gray-300 rounded-md w-56 mb-2.5"></div>
      <div class="flex gap-2">
        <div class="h-6 bg-gray-300 rounded-md w-32"></div>
        <div class="h-6 bg-gray-300 rounded-md w-32"></div>
      </div>
    </div>
    <div v-else class="rounded-lg mb-6 bg-white">
      <h2 class="text-lg font-normal mx-6 py-4 border-b">
        Certificate Information
      </h2>
      <h2 class="text-lg font-normal px-6 py-4 flex gap-1 items-center">
        Status:
        <div
          v-if="
            medicalLetter?.data?.status == 0 || medicalLetter?.data?.status == 4
          "
          class="text-teal font-bold text-xl"
        >
          In Process
        </div>
        <div
          v-else-if="medicalLetter?.data?.status == 1"
          class="text-teal font-medium text-xl flex justify-between w-full items-center"
        >
          Delivered
          <button
            class="bg-teal px-8 py-2 text-white rounded-lg text-base cursor-pointer"
            @click="
              downloadFile(
                medicalLetter?.data?.meta?.uploaded_medical_letter_url,
                medicalLetter?.data?.medical_letter?.name
              )
            "
          >
            View Certificate
          </button>
        </div>
        <div v-else class="text-red font-medium text-xl">Rejected/Refunded</div>
      </h2>
      <div class="px-6 pb-4 capitalize" v-if="medicalLetter?.data?.status == 2">
        <span class="text-textGray"> Reason:</span>
        {{ medicalLetter?.data?.rejection_reason }}
      </div>
    </div>

    <div class="mb-4" v-if="role === 'doctor'">
      <div v-if="isLoading" class="border-gray-200 animate-pulse">
        <div class="h-6 bg-gray-300 rounded-md mb-4 w-32"></div>
        <div class="h-16 bg-gray-300 rounded-md my-4"></div>
      </div>
      <div v-else>
        <div class="text-sm mb-2">
          Note From MO: <span class="text-textGray">(about patient)</span>
        </div>
        <div class="bg-white rounded p-4 border text-sm">
          {{ medicalLetter?.data?.mo_notes }}
        </div>
      </div>
    </div>

    <!-- Accordion for Patient Information -->
    <div class="bg-white mb-5 py-4">
      <div class="rounded pb-4">
        <div
          class="flex justify-between items-center bg-teal bg-opacity-10 p-4 cursor-pointer"
          @click="togglePatientInfo"
        >
          <h2 class="cursor-pointer text-lg font-medium py-2">
            Patient Information
          </h2>
          <div class="material-icons text-teal w-0 mr-5">
            {{
              isPatientInfoVisible
                ? "expand_less_outlined"
                : "expand_more_outlined"
            }}
          </div>
        </div>

        <div v-if="isPatientInfoVisible" class="">
          <PatientInformationForm
            :patient="medicalLetter?.data"
            :purposeOfLetter="
              medicalLetter?.data?.medical_evidence?.purpose_of_letter
            "
            :isLoading="isLoading"
          />
        </div>
      </div>
      <div v-if="isLoading" class="animate-pulse">
        <div v-for="n in 5" :key="n">
          <div class="h-16 bg-gray-300 rounded-md my-4"></div>
        </div>
      </div>
      <!-- Accordion for Medical Information -->

      <div v-else>
        <div
          class=""
          v-for="(section, index) in sortedMedicalLetterQuestions"
          :key="index"
        >
          <div
            class="flex justify-between items-center bg-teal bg-opacity-10 p-4 mb-4 cursor-pointer"
            @click="toggleMedicalInfo(index)"
          >
            <h2 class="cursor-pointer text-lg font-medium py-2">
              {{ section?.name }}
            </h2>
            <div class="material-icons text-teal w-0 mr-5">
              {{
                isMedicalInfoVisible === index
                  ? "expand_less_outlined"
                  : "expand_more_outlined"
              }}
            </div>
          </div>
          <div v-if="isMedicalInfoVisible === index" class="py-2">
            <QuestionsForm
              :data="section?.questions"
              :key="section?.questions"
            />
          </div>
        </div>
      </div>
    </div>

    <MedicalInformation
      :medicalFiles="medicalLetter?.data?.medical_evidence"
      :photoId="medicalLetter?.data?.image"
      :isLoading="isLoading"
      @view-file="handleViewFile"
    />

    <div
      class="flex mt-4"
      v-if="
        medicalLetter?.data?.status == 0 || medicalLetter?.data?.status == 4
      "
    >
      <button
        class="border border-teal text-teal font-medium w-1/2 p-3 rounded-md mr-2"
        @click="openForwardModal(false)"
        v-if="role != 'medical_officer'"
      >
        Reject & Refund
      </button>
      <button
        class="bg-teal text-white font-medium p-3 rounded-md"
        :class="role != `medical_officer` ? 'w-1/2' : 'w-full'"
        @click="
          role != `medical_officer`
            ? navigate(medicalLetter?.data?.id)
            : openForwardModal(true)
        "
      >
        {{
          role != "medical_officer"
            ? "Generate Certificate"
            : "Forward To Doctor"
        }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import RejectReasonModal from "@/components/newUi/MedicalLetters/RejectReasonModal.vue";
import QuestionsForm from "../../../components/newUi/MedicalLetters/QuestionsForm.vue";
import PatientInformationForm from "@/components/newUi/MedicalLetters/PatientInformationForm.vue";
import MedicalInformation from "@/components/newUi/MedicalLetters/MedicalInformation.vue";
import VideoPlayer from "@/components/newUi/atoms/VideoPlayer.vue";
import useDownloadFile from "@/composables/useDownloadFile";
import SuccessIcon from "@/assets/icons/success.svg";
import Swal from "sweetalert2";

const { downloadFile } = useDownloadFile();
const isLoading = ref(true);
const role = localStorage.getItem("role");
const store = useStore();
const router = useRouter();
const isApproved = ref(false);
const route = useRoute();
const letterId = route.params.letterId;
const medicalLetter = computed(() => {
  return store.getters["PMLModule/getMedicalLetterRequest"];
});

const medicalLetterQuestions = computed(() => {
  return store.getters["PMLModule/getMedicalLetterRequestQuestions"];
});

const medicalLetterId = computed(() => {
  return medicalLetter.value && medicalLetter.value?.data?.medical_letter?.id;
});

const navigate = (letterId) => {
  router.push({ name: "confirmMedicalLetter", params: { letterId: letterId } });
};

const isModalVisible = ref(false);
const selectedFile = ref(null);
const videoSrc = ref("");
const doctorsProfiles = ref("");

const isForwardModalVisible = ref(false);
const rejectionReason = ref("");

const isPatientInfoVisible = ref(false);
const isMedicalInfoVisible = ref(null);

const sortedMedicalLetterQuestions = ref([]);

const sortMedicalLetterQuestions = () => {
  if (
    !medicalLetterQuestions.value ||
    !medicalLetterQuestions.value?.questionnaires ||
    medicalLetterQuestions.value?.questionnaires?.length === 0
  ) {
    sortedMedicalLetterQuestions.value = [];
    return;
  }

  sortedMedicalLetterQuestions.value =
    medicalLetterQuestions.value.questionnaires[0].categories.sort((a, b) => {
      if (a.name === "Consent") return 1;
      if (b.name === "Consent") return -1;
      return 0;
    });
};

watch(medicalLetterQuestions, sortMedicalLetterQuestions, { immediate: true });

const openForwardModal = (isApprovedClicked) => {
  isApproved.value = isApprovedClicked;
  isForwardModalVisible.value = true;
};

const closeRejectModal = () => {
  isApproved.value = false;
  isForwardModalVisible.value = false;
  rejectionReason.value = "";
};

const submitRejection = (payload) => {
  if (!role.includes("doctor")) {
    store.dispatch("PMLModule/approvedMedicalLetterRequest", {
      medical_letter_request: letterId,
      mo_notes: payload.reason,
      doctor: payload.doctor,
    });
    Swal.fire({
      title: `Medical Letter has been forwarded to doctor successfully!`,
      iconHtml: `<img src="${SuccessIcon}" />`,
      width: "32em",
      confirmButtonText: "Done",
      confirmButtonColor: "rgba(0, 164, 153, 1)",
      customClass: {
        title: "popup-title",
        popup: "border-radius",
        content: "custom-text",
        icon: "icon-border",
        confirmButton: "medical-letter-yes-button",
      },
      didClose: () => {
        router.push({ name: "hq-medicalLettersDashboard" });
      },

      showConfirmButton: false,
    });
  } else {
    rejectionReason.value = payload.rejectReason;
    store.dispatch("PMLModule/rejectMedicalLetterRequest", {
      medical_letter_request: letterId,
      rejection_reason: payload.reason,
    });
    Swal.fire({
      title: `Medical Letter has been rejected successfully!`,
      iconHtml: `<img src="${SuccessIcon}" />`,
      width: "32em",
      confirmButtonText: "Done",
      confirmButtonColor: "rgba(0, 164, 153, 1)",
      customClass: {
        title: "popup-title",
        popup: "border-radius",
        content: "custom-text",
        icon: "icon-border",
        confirmButton: "medical-letter-yes-button",
      },
      didClose: () => {
        router.push({ name: "hq-medicalLettersDashboard" });
      },

      showConfirmButton: false,
    });
  }
  closeRejectModal();
};

const handleViewFile = (file) => {
  selectedFile.value = file;
  if (file?.name?.includes("Evidence Video")) {
    isModalVisible.value = true;
    videoSrc.value = file?.url;
  } else {
    downloadFile(file?.url, file?.name);
  }
};

const togglePatientInfo = () => {
  isPatientInfoVisible.value = !isPatientInfoVisible.value;
};

const toggleMedicalInfo = (index) => {
  isMedicalInfoVisible.value =
    isMedicalInfoVisible.value === index ? null : index;
};

watch(() => {
  medicalLetterId.value &&
    store
      .dispatch("PMLModule/fetchMedicalLetterRequestQuestions", {
        letterRequestId: letterId,
        letterId: medicalLetterId.value,
      })
      .then(() => {
        isLoading.value = false;
      });
}, [medicalLetter.value]);

onMounted(() => {
  if (role == "doctor") {
    store
      .dispatch("PMLModule/fetchMedicalLetterRequestByDoctor", {
        letterId: letterId,
      })
      .then(() => {});
  } else {
    store
      .dispatch("PMLModule/fetchMedicalLetterRequest", {
        letterId: letterId,
      })
      .then(() => {});

    store.dispatch("PMLModule/fetchDoctorsProfileMO").then((response) => {
      doctorsProfiles.value = response;
    });
  }
});
</script>

<style scoped></style>
