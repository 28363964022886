<!-- @format -->

<template>
  <div class="bg-white px-6 py-4 rounded-md shadow-lg w-1/3">
    <div class="flex justify-end items-center mb-4">
      <button
        class="material-icons bg-red-500 text-sm rounded-full text-white w-5 font-bold"
        @click="closeModal"
      >
        close
      </button>
    </div>
    <div v-if="role !== 'doctor'" class="">
      <label class="text-textGray my-10"
        >{{ !selectedDoctor ? "Select Doctor" : "Selected Doctor" }}
      </label>
      <v-select
        v-model="selectedDoctor"
        label="fullName"
        placeholder="Select Doctor"
        :clearable="false"
        :options="fullDoctorProfiles"
        @update:modelValue="choosenDoctors"
        class="w-full mt-3 mb-4"
      ></v-select>
      <div v-if="selectedDoctor" class="capitalize gap-4 my-4">
        <transition name="slide-fade">
          <DoctorProfile
            v-if="selectedDoctor"
            :doctor="docterChoosen"
            :key="docterChoosen"
            :loading="loadingDoctor"
          />
        </transition>
      </div>
    </div>
    <label class="text-textGray my-10">{{
      isApproved ? "Add Notes" : "Add Reason"
    }}</label>
    <textarea
      v-model="reason"
      class="w-full p-2 border border-gray-300 rounded-md mt-3 h-32"
      rows="3"
      :placeholder="
        isApproved ? 'Add Notes.....' : 'Type your reason for rejection...'
      "
      maxlength="512"
    ></textarea>
    <div class="flex justify-end mt-4">
      <button
        class="bg-teal text-white px-4 py-2 rounded-md"
        :class="{
          'bg-opacity-60 cursor-not-allowed':
            role === 'doctor' ? !reason : !reason || !selectedDoctor,
        }"
        :disabled="role !== 'doctor' ? !reason || !selectedDoctor : !reason"
        @click="handleSubmit"
      >
        {{ role !== "doctor" ? "Submit" : "Reject & Refund" }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, toRefs, computed } from "vue";
import DoctorProfile from "../../../components/newUi/MedicalLetters/DoctorProfile.vue";
const props = defineProps({
  doctorsProfiles: {
    type: Array,
  },
  isApproved: {
    type: Boolean,
  },
});

const { doctorsProfiles, isApproved } = toRefs(props);
const docterChoosen = ref({
  id: "",
  first_name: "",
  last_name: "",
  gmc_number: "",
  doctor_qualification: "",
  practice: "",
  gmc_status: "",
  signature: "",
});

const role = localStorage.getItem("role");

const selectedDoctor = ref();

const fullDoctorProfiles = computed(() => {
  return doctorsProfiles.value?.map((doctor) => ({
    ...doctor,
    fullName: `Dr. ${doctor.first_name} ${doctor.last_name}`,
  }));
});

const choosenDoctors = (doctor) => {
  if (doctor) {
    selectedDoctor.value = "Dr. " + doctor.first_name + " " + doctor.last_name;
    docterChoosen.value.first_name = doctor.first_name;
    docterChoosen.value.last_name = doctor.last_name;
    docterChoosen.value.gmc_number = doctor.gmc_number;
    docterChoosen.value.doctor_qualification = doctor.doctor_qualification;
    docterChoosen.value.practice = doctor.practice;
    docterChoosen.value.gmc_status = doctor.gmc_status;
    docterChoosen.value.signature = doctor.doctor_signature;
    docterChoosen.value.id = doctor.user_id;
  } else {
    selectedDoctor.value = null;
    docterChoosen.value = {
      id: "",
      first_name: "",
      last_name: "",
      gmc_number: "",
      doctor_qualification: "",
      practice: "",
      gmc_status: "",
      signature: "",
    };
  }
};

const emits = defineEmits(["close-modal", "submit-modal"]);

const reason = ref("");

const closeModal = () => {
  emits("close-modal");
  reason.value = "";
};

const handleSubmit = () => {
  const payload = { reason: reason.value, doctor: docterChoosen.value.id };
  emits("submit-modal", payload);
  closeModal();
};
</script>

<style scoped>
textarea {
  resize: none;
}

::v-deep .vs__dropdown-toggle {
  padding: 10px;
  background-color: white;
  cursor: pointer !important;
}
::v-deep ul li:not(:hover) {
  background-color: white !important;
  color: black;
}
::v-deep ul li:hover {
  background-color: #00a499 !important;
  color: white;
}

::v-deep .vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 40px);
}

::v-deep .vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 95%;
  overflow: hidden;
}
::v-deep ul li {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
